import commonAxios from "./http";
class PoiList {
  constructor() {
    this.cacheFileJSON = {};
    this.cache404URL = {};
  }

  getData(params) {
    if (Object.keys(this.cacheFileJSON).length > 70) {
      this.cacheFileJSON = {};
    }
    return new Promise(async (yes) => {
      const res = await commonAxios({
        method: "get",
        url: "/api/v1/poi/emap",
        params,
      });
      if (res && res.data && res.data.items) {
        /** promise数组 */
        const promiseArr = res.data.items.map((url) => {
          return new Promise((resolve) => {
            if (!this.cacheFileJSON[url] && !this.cache404URL[url]) {
              this.importJSON(url).then((data) => {
                if (data && data.length) {
                  this.cacheFileJSON[url] = JSON.stringify(data);
                }
                resolve(data);
              });
            } else if (this.cacheFileJSON[url]) {
              resolve(JSON.parse(this.cacheFileJSON[url]));
            } else {
              resolve([]);
            }
          });
        });
        Promise.all(promiseArr)
          .then((res) => {
            yes({
              code: 0,
              data: res.flat(),
            });
          })
          .catch((e) => console.log("sasas", e));
      }
    });
  }

  async importJSON(url) {
    return new Promise((resolve) => {
      try {
        const request = new XMLHttpRequest();
        request.open("get", url);
        request.onreadystatechange = () => {
          if (request.readyState == 4 && request.status == 200) {
            var json = JSON.parse(request.responseText);
            resolve(json || []);
          } else if (request.readyState == 4) {
            this.cache404URL[url] = true;
            resolve([]);
          }
        };
        request.send(null);
      } catch (error) {
        console.log("catch", error);
      }
    });
  }
}

const _poiList = new PoiList();

export default _poiList;
