<template>
  <div
    class="do-fn-line"
    :id="containerID"
    :style="{ width: '100%', height: '100%', pointerEvents: 'none' }"
  ></div>
</template>

<script setup>
import { onMounted, defineEmits, defineProps, watch } from "vue";
import Utils from "@/util";
import * as echarts from "echarts";
const containerID = Utils.guid();
const emit = defineEmits(["cb"]);
const props = defineProps(["data", "percent"]);
let charts = null;

onMounted(() => {
  var chartDom = document.getElementById(containerID);
  if (!chartDom) return;
  var myChart = echarts.init(chartDom, null, { renderer: "svg" });
  var option = getConfig(props.data, myChart);
  charts = myChart;
  option && myChart.setOption(option);
  emit("cb", myChart);
});

watch(
  () => props.data,
  (end, start) => {
    charts.setOption(getConfig(end, charts), { notMerge: true });
  }
);

const getConfig = (data, chart) => {
  let color = [
    "#D0E1FD",
    "#AAC9FB",
    "#92B7FF",
    "#73A3FF",
    "#3A89FF",
    "#0057FF",
    "#013392",
  ];
  let copeData = JSON.parse(JSON.stringify(data || []));
  // 处理数据两边平衡
  if (copeData.length > 1) {
    let total = copeData.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue?.value || 0);
    }, 0);

    copeData = copeData
      .map((el) => ({
        ...el,
        value: el.value / total,
      }))
      .sort((a, b) => b.value - a.value);

    copeData.splice(
      0,
      0,
      ...copeData.splice(
        parseInt(copeData.length / 2),
        parseInt(copeData.length / 2)
      )
    );
  }

  let list = copeData.map((el, index) => ({
    ...el,
    label: {
      formatter: `{colorddd|}{name|{b}}\n{num|{d}}%  `,
      lineHeight: 14,
      overflow: "none",
      edgeDistance: 0,
      rich: {
        name: {
          color: "rgba(3,5,15,0.65)",
          fontWeight: "400",
          padding: [0, 0, 0, 4],
          align: "left",
        },
        num: {
          color: "#03050F",
          fontWeight: "500",
          padding: [0, 0, 0, 8],
          align: "left",
        },
        colorddd: {
          width: 4,
          height: 4,
          backgroundColor: color[index],
          borderRadius: 50,
          align: "left",
        },
      },
    },
  }));
  return {
    series:
      {
        type: "pie",
        color: color,
        avoidLabelOverlap: true,
        radius: "65%",
        labelLine: {
          lineStyle: {
            color: "rgba(3,5,15,0.45)",
            width: 1,
          },
        },
        label: {
          alignTo: "none",
          edgeDistance: 20,
          margin: 10,
        },
        labelLayout: function (params) {
          const isLeft = params.labelRect.x < chart.getWidth() / 2;
          const points = params.labelLinePoints;
          if (!points) return;
          points[2][0] = isLeft
            ? params.labelRect.x
            : params.labelRect.x + params.labelRect.width;
          points[1][1] = params.labelRect.y + params.labelRect.height;
          points[2][1] = params.labelRect.y + params.labelRect.height;
          return {
            labelLinePoints: points,
          };
        },
        data: list || [],
      } || {},
  };
};
</script>

<style lang="scss">
// 因为真机没显示css样式所以放到全局
</style>
