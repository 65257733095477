<template>
  <canvas
    :id="containerID"
    class="do-fn-testing-charts"
    :style="{ width: '100%', height: '100%' }"
  ></canvas>
</template>

<script setup>
import {
  onMounted,
  defineEmits,
  defineProps,
  watch,
  getCurrentInstance,
  nextTick,
} from "vue";
import Utils from "@/util";
const { appContext } = getCurrentInstance();
const { $ComJs } = appContext.config.globalProperties; //全局属性解构
const containerID = Utils.guid();
const emit = defineEmits(["cb"]);
const props = defineProps(["data"]);
let canvasClass = null;

onMounted(() => {
  circleInfo();
});

watch(
  () => props.data,
  (end, start) => {
    canvasClass.updata(end);
  }
);

let circleInfo = () => {
  class Circle {
    constructor(x, y, info) {
      this.x = x;
      this.y = y;
      this.info = info;
    }
  }

  class RandomCircle {
    constructor(obj) {
      this.c = document.getElementById(obj.id);

      this.ctx1 = this.c.getContext("2d"); // 中间圆的图层
      this.ctx = this.c.getContext("2d"); // 数据图层
      this.c.style.width =
        this.c.getBoundingClientRect().width * window.devicePixelRatio;
      this.c.style.height =
        this.c.getBoundingClientRect().height * window.devicePixelRatio;
      this.c.width =
        this.c.getBoundingClientRect().width * window.devicePixelRatio;
      this.c.height =
        this.c.getBoundingClientRect().height * window.devicePixelRatio;
      this.dWidth = this.c.width; // canvas宽度
      this.dHeight = this.c.height; // canvas高度

      this.border = 10; // 边框用来添加碰撞计算后添加的距离

      this.total = obj.data.length || 0; // 数据总数
      this.data = obj.data || []; //数据

      this.circleArray = [];
      this.devicePixelRatio = window.devicePixelRatio; // 解决移动端模糊  设备像素比
      this.textSize = $ComJs.bili * 0.24 * window.devicePixelRatio; // 文字大小
    }

    // 渲染logo和文字
    drawOneCircle(c, index) {
      let ctx = this.ctx;

      ctx.beginPath();

      if (c.info.image) {
        let img = new Image();
        img.src = c.info.image;
        img.width = 20 * this.devicePixelRatio;
        img.height = 20 * this.devicePixelRatio;
        let totleH = img.height + this.textSize;
        img.onload = () => {
          // ctx.drawImage(
          //   img,
          //   c.x - img.width / 2,
          //   c.y - totleH / 2,
          //   img.width,
          //   img.height
          // );
          this.drawRoundedImage(
            ctx,
            img,
            c.x - img.width / 2,
            c.y - totleH / 2,
            img.width / 2
          );
        };
        ctx.font = `500 ${this.textSize}px PingFangSC-Regular, PingFang SC`;
        ctx.fillStyle = "rgba(3,5,15,0.85)";
        ctx.textAlign = "center";
        ctx.fillText(c.info.name, c.x, c.y + totleH / 2);
        return;
      }
      ctx.fillStyle = "rgba(3,5,15,0.25)";
      ctx.font = `400 ${this.textSize}px PingFangSC-Regular, PingFang SC`;
      ctx.textAlign = "center";
      ctx.fillText(c.info.name, c.x, c.y + this.textSize / 2);
    }

    // 添加图片加圆角
    drawRoundedImage(ctx, img, x, y, r) {
      ctx.beginPath();
      ctx.save();
      var d = 2 * r;
      var cx = x + r;
      var cy = y + r;
      ctx.arc(cx, cy, r, 0, 2 * Math.PI);
      ctx.clip();
      ctx.drawImage(img, x, y, d, d);
      ctx.restore();
    }

    // 判断当前左右边是否有碰撞
    circleXY(info, axios, is) {
      let centerR = 30 * this.devicePixelRatio;
      let centerX = this.dWidth / 2;
      let centerY = this.dHeight / 2;

      const canvas = document.createElement("canvas");
      const dom = canvas.getContext("2d");
      dom.font = `400 ${this.textSize}px PingFangSC-Regular, PingFang SC`;
      let width = dom.measureText(info.name).width;
      let imgR = (20 * this.devicePixelRatio + this.textSize) / 2;
      let height = info.image ? imgR : (this.textSize * 0.9) / 2;

      let w = 0;
      let h = 0;

      // 判断字和中间圆不重叠
      if (
        axios[0] - width / 2 < centerX + centerR &&
        axios[0] + width / 2 > centerX + centerR
      ) {
        w = centerX + centerR - (axios[0] - width / 2);
      } else if (
        axios[0] - width / 2 > centerX - centerR &&
        axios[0] + width / 2 < centerX - centerR
      ) {
        w = axios[0] - width / 2 - centerX - centerR;
      } else if (
        axios[1] - height / 2 < centerY + centerR &&
        axios[1] + height / 2 > centerY + centerR
      ) {
        h = centerY + centerR - (axios[1] - height / 2);
      } else if (
        axios[1] - height / 2 > centerY - centerR &&
        axios[1] + height / 2 < centerY - centerR
      ) {
        h = axios[1] - height / 2 - (centerY - centerR);
      }
      let max = 0;
      let maxH = 0;

      // 盒子碰撞后生成多余的宽度和高度
      if (is) {
        this.circleArray.forEach((el) => {
          let elW = dom.measureText(info.name).width / 2;
          let elH = el.info.image ? imgR : (this.textSize * 0.9) / 2;
          if (centerX > axios[0]) {
            if (
              axios[0] + width / 2 > el.x - elW &&
              axios[1] - height / 2 < el.y + elH &&
              axios[1] + height / 2 > el.y - elH
            ) {
              let a = axios[0] + width / 2;
              let b = el.x - elW;
              max = b - a;
              w = max < w ? max : w;
            }
          } else if (centerX < axios[0]) {
            if (
              axios[0] - width / 2 < el.x + elW &&
              axios[1] - height / 2 < el.y + elH &&
              axios[1] + height / 2 > el.y - elH
            ) {
              let a = axios[0] - width / 2;
              let b = el.x + elW;
              max = b - a + this.border;
              w = max > w ? max : w;
            }
          }
        });
      }
      return { w: w, h: h };
    }

    // 画中心圆
    drawArc(c, ctx, color) {
      // 画圆
      ctx.beginPath(); //按照下边参数开始绘制新路径
      ctx.arc(c.x, c.y, c.r, 0, 2 * Math.PI);
      ctx.closePath(); //关闭路径
      ctx.strokeStyle = `rgb(255,255, 255, ${1 - (c.i * 3) / 10})`;
      if (color) {
        ctx.fillStyle = color;
      } else {
        ctx.fillStyle =
          c.i === 0
            ? `rgba(0, 87, 255, 1)`
            : `rgb(208, 222, 255, ${1 - (c.i * 3) / 10})`;
      }

      ctx.stroke();
      ctx.fill();
    }

    // 初始渲染
    init() {
      if (!this.data.length) return;
      // this.drawArc
      let r = 30 * this.devicePixelRatio;
      let x = this.dWidth / 2;
      let y = this.dHeight / 2;
      let i = 0;
      // 添加中心圆边框圆
      while (true) {
        this.drawArc(
          { x, y, r: r + 20 * this.devicePixelRatio * i, i },
          this.ctx1
        );
        if (x + r * i > this.dWidth) {
          break;
        }
        ++i;
      }
      // 添加内层的白色和蓝色
      this.drawArc({ x, y, r: r, i: 0 }, this.ctx1, "#fff");
      this.drawArc({ x, y, r: r - 5, i: 0 }, this.ctx1);
      // 添加中心圆
      this.ctx1.fillStyle = "#fff";
      this.ctx1.font = `bold ${this.textSize}px PingFangSC-Regular, PingFang SC`;
      this.ctx1.textAlign = "center";
      this.ctx1.fillText(
        `${this.total || 0}`,
        x,
        y - 5 * this.devicePixelRatio
      );
      this.ctx1.fillStyle = "rgba(255,255,255,0);";
      this.ctx1.font = `400 ${this.textSize}px PingFangSC-Regular, PingFang SC`;
      this.ctx1.textAlign = "center";
      this.ctx1.fillText("共计数量", x, y + 10 * this.devicePixelRatio);

      let centerR = 50 * this.devicePixelRatio;
      let centerX = this.dWidth / 2;
      let centerY = this.dHeight / 2;
      let border = [];
      // 获取当前25个值的位置
      for (let i = 0; i <= 350; i += 45) {
        border.push([
          parseInt(
            centerX +
              Math.cos(((Math.PI * 2) / 360) * i) *
                (centerR + $ComJs.bili * 0.2 * this.devicePixelRatio)
          ),
          parseInt(
            centerY +
              Math.sin(((Math.PI * 2) / 360) * i) *
                (centerR + $ComJs.bili * 0.2 * this.devicePixelRatio)
          ),
        ]);
      }
      for (let i = 0; i <= 350; i += 45) {
        border.push([
          parseInt(
            centerX +
              Math.cos(((Math.PI * 2) / 360) * (i - 22)) *
                (centerR + $ComJs.bili * 1.2 * this.devicePixelRatio)
          ),
          parseInt(
            centerY +
              Math.sin(((Math.PI * 2) / 360) * (i - 22)) *
                (centerR + $ComJs.bili * 0.9 * this.devicePixelRatio)
          ),
        ]);
      }

      [-43, 0, 40, 140, 180, 220].forEach((num) => {
        border.push([
          parseInt(
            centerX +
              Math.cos(((Math.PI * 2) / 360) * -num) *
                (centerR + $ComJs.bili * 1.2 * this.devicePixelRatio)
          ),
          parseInt(
            centerY +
              Math.sin(((Math.PI * 2) / 360) * -num) *
                (centerR + $ComJs.bili * 1.2 * this.devicePixelRatio)
          ),
        ]);
      });

      border.push([
        $ComJs.bili * 0.8 * this.devicePixelRatio,
        centerY * 2 - 15 * this.devicePixelRatio,
      ]);

      border.push([
        centerX * 2 - $ComJs.bili * 1 * this.devicePixelRatio,
        centerY * 2 - 15 * this.devicePixelRatio,
      ]);

      border.push([
        this.dWidth - $ComJs.bili * 0.8 * this.devicePixelRatio,
        $ComJs.bili * 0.2 * this.devicePixelRatio,
      ]);

      // 重新设置数据在圆的x,y轴位置
      let si = {
        0: 1,
        1: 2,
        2: 7,
        3: 5,
        4: 6,
        5: 13,
        6: 9,
        7: 12,
        8: 8,
        9: 10,
        10: 0,
        11: 4,
        12: 3,
        13: 17,
        14: 21,
        15: 18,
        16: 19,
        17: 16,
        18: 14,
        19: 11,
        20: 15,
        21: 23,
        22: 22,
        23: 24,
        24: 20,
      };
      for (let index = 0; index < this.data.length; index++) {
        if (si[index] < 8) {
          let arr = this.circleXY(this.data[index], border[si[index]]);
          this.circleArray.push(
            new Circle(
              border[si[index]][0] +
                Number(`${centerX === border[si[index]][0] ? 0 : arr.w}`),
              border[si[index]][1] + Number(arr.h),
              this.data[index]
            )
          );
        } else if (index < 24) {
          let arr = this.circleXY(this.data[index], border[si[index]], true);
          this.circleArray.push(
            new Circle(
              border[si[index]][0] +
                Number(`${centerX === border[si[index]][0] ? 0 : arr.w + 0}`),
              border[si[index]][1] +
                (index === 9
                  ? -($ComJs.bili * 0.08 * this.devicePixelRatio)
                  : 0),
              this.data[index]
            )
          );
        }
      }
      // 根据半径从大到小画圆。
      this.circleArray.forEach((c, index) => {
        this.drawOneCircle(c, index);
      });
    }

    // 数据更新重新渲染
    updata(data = []) {
      this.total = data.length || 0;
      let cope = data.splice(0, 25);
      let img = cope.filter((el) => el.image).splice(0, 10);
      let list = [];
      cope.forEach((el) => {
        img.findIndex((l) => l.name === el.name) !== -1 ? null : list.push(el);
      });

      list = list.map((el) => ({ ...el, image: null }));
      this.data = [...img, ...list];
      this.circleArray = [];

      nextTick(() => {
        this.ctx.clearRect(0, 0, this.dWidth, this.dHeight, cope);
        this.ctx1.clearRect(0, 0, this.dWidth, this.dHeight);
        this.init();
      });
    }
  }
  canvasClass = new RandomCircle({
    id: containerID,
    data: props.data, // 配置数量
  });
};
</script>

<style lang="scss">
// 因为真机没显示css样式所以放到全局
</style>
