<template>
  <div class="do-page-poi-info">
    <div :style="{ height: '1.74rem' }"></div>
    <div class="box">
      <div class="category-tabbar">
        <div
          class="item-title"
          :style="{ marginTop: '0.32rem', marginBottom: '0.24rem' }"
        >
          <div class="left">覆盖客流量</div>
          <div class="right">更新：月度</div>
        </div>
        <div class="item-type">
          <div>
            <div class="c45">业态：</div>
            <div class="c65">
              {{
                (stopInfo?.category_list?.slice(0, 2) || []).join("-") || "-"
              }}
            </div>
          </div>
          <div>
            <div class="c45">
              {{
                (+dataInfo?.type === 3 && "居住人口") ||
                (+dataInfo?.type === 4 && "工作人口") ||
                "月客流量指数"
              }}：
            </div>
            <div
              class="c65"
              :style="{
                fontWeight: 'bold',
                fontFamily: 'DINPro-Bold, DINPro;',
              }"
            >
              {{
                +dataInfo?.type === 3
                  ? dataInfo?.num || "-"
                  : +dataInfo?.type === 4
                  ? dataInfo?.num || "-"
                  : dataInfo?.flow?.month || "-"
              }}
            </div>
          </div>
        </div>
        <picture-frame
          title="近30天客流量情况"
          :prop-stype="{ height: '4.2rem', margin: '0.24rem 0' }"
          v-if="
            (+dataInfo?.type === 1 || +dataInfo?.type === 2) &&
            dataInfo?.flow?.day
          "
        >
          <Line
            :data="dataInfo?.flow?.day || []"
            :dataZoom="true"
            :legend="['工作日', '节假日']"
            :x="
              (dataInfo?.flow?.day || []).map((el) =>
                new Date(el.date).getDate()
              )
            "
          ></Line>
        </picture-frame>
        <picture-frame
          title="节假日-小时客流量情况"
          :prop-stype="{ height: '4.2rem', margin: '0.24rem 0 0' }"
          v-if="
            (+dataInfo?.type === 1 || +dataInfo?.type === 2) &&
            dataInfo?.flow?.hour?.rest?.length
          "
        >
          <Line
            :data="dataInfo?.flow?.hour?.rest?.map((el) => el * 100) || []"
            :x="getSection(24, 0)"
            :percent="true"
          ></Line>
        </picture-frame>
        <picture-frame
          title="工作日-小时客流量情况"
          :prop-stype="{ height: '4.2rem', margin: '0.24rem 0 0' }"
          v-if="
            (+dataInfo?.type === 1 || +dataInfo?.type === 2) &&
            dataInfo?.flow?.hour?.work?.length
          "
        >
          <Line
            :data="dataInfo?.flow?.hour?.work?.map((el) => el * 100) || []"
            :x="getSection(24, 0)"
            :percent="true"
          ></Line>
        </picture-frame>
        <picture-frame
          v-if="
            (+dataInfo?.type === 3 &&
              (dataInfo.info.avg_price ||
                dataInfo?.info?.building_type ||
                dataInfo?.info?.property_costs ||
                dataInfo?.info?.build_year ||
                dataInfo?.info?.building_cnt ||
                dataInfo?.info?.house_cnt ||
                dataInfo?.info?.property_company ||
                dataInfo?.info?.developer)) ||
            (dataInfo?.type === 4 &&
              (dataInfo?.info?.avg_rent_price ||
                dataInfo?.info?.property_category ||
                dataInfo?.info?.property_costs ||
                dataInfo?.info?.area ||
                dataInfo?.info?.floor_cnt ||
                dataInfo?.info?.elevator_cnt ||
                dataInfo?.info?.property_company))
          "
          :title="
            (+dataInfo?.type === 3 && '小区信息') ||
            (+dataInfo?.type === 4 && '办公信息')
          "
          :prop-stype="{ height: '100%', margin: '0.24rem 0 0' }"
        >
          <Explain :type="dataInfo?.type" :dataInfo="dataInfo"></Explain>
        </picture-frame>

        <picture-frame
          title="商场内业态占比"
          :prop-stype="{ height: '5rem', margin: '0.24rem 0' }"
          v-if="+dataInfo?.type === 2 && dataInfo?.mall_format?.length"
        >
          <Pie
            :data="
              dataInfo?.mall_format?.map(
                (el) =>
                  ({
                    name: el.name,
                    value: el.num,
                  } || [])
              )
            "
          ></Pie>
        </picture-frame>
        <div
          class="item-title"
          :style="{ marginTop: '0.4rem', marginBottom: '0.24rem' }"
        >
          <div class="left">周边数据</div>
          <div class="right">范围：{{ +stopInfo.type === 2 ? 3 : 1 }}km</div>
        </div>
        <div class="bg-fff">
          <div class="item-range-data">
            <div>
              <div class="c85" :style="{ fontSize: '0.4rem' }">
                {{ dataInfo?.around?.live_cnt || "-" }}
              </div>
              <div class="c45">居住人群</div>
            </div>
            <div>
              <div class="c85" :style="{ fontSize: '0.4rem' }">
                {{ dataInfo?.around?.work_cnt || "-" }}
              </div>
              <div class="c45">工作人群</div>
            </div>
          </div>
          <div
            class="item-range-data item-reange-num"
            :style="{ marginBottom: '0.16rem' }"
          >
            <div>
              <div
                :class="`c85 ${dataInfo?.around?.live ? 'underline' : ''}`"
                @click="() => dataInfo?.around?.live && goAround(1)"
                :style="{
                  fontSize: '0.4rem',
                  color: '#0057FF',
                  fontWeight: 'bold',
                }"
              >
                {{ dataInfo?.around?.live || "-" }}
              </div>
              <div class="c45">住宅区数量</div>
            </div>
            <div>
              <div
                :class="`c85 ${dataInfo?.around?.work ? 'underline' : ''}`"
                @click="() => dataInfo?.around?.work && goAround(2)"
                :style="{
                  fontSize: '0.4rem',
                  color: '#0057FF',
                  fontWeight: 'bold',
                }"
              >
                {{ dataInfo?.around?.work || "-" }}
              </div>
              <div class="c45">办公区数量</div>
            </div>
            <div>
              <div
                :class="`c85 ${
                  dataInfo?.around?.street_shop ? 'underline' : ''
                }`"
                @click="() => dataInfo?.around?.street_shop && goAround(3)"
                :style="{
                  fontSize: '0.4rem',
                  color: '#0057FF',
                  fontWeight: 'bold',
                }"
              >
                {{ dataInfo?.around?.street_shop || "-" }}
              </div>
              <div class="c45">街铺数量</div>
            </div>
          </div>
          <calss-tag
            :style="{ paddingBottom: '0.16rem' }"
            title="住宅情况"
            :tglWidth="$ComJs.bili * 1.78"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.around?.price?.live?.avg_rent_price) ||
              Number(dataInfo?.around?.price?.live?.avg_sale_price)
            "
            right="TGL"
            :config="[
              { name: '平均售价：', value: 'a', right: 'c' },
              { name: '平均租金：', value: 'b', right: 'd' },
            ]"
            :info="{
              a:
                (dataInfo?.around?.price?.live?.avg_sale_price &&
                  `${dataInfo?.around?.price?.live?.avg_sale_price}元/㎡`) ||
                '-',
              b:
                (dataInfo?.around?.price?.live?.avg_rent_price &&
                  `${dataInfo?.around?.price?.live?.avg_rent_price}元/㎡/月`) ||
                '-',
              c:
                dataInfo?.around?.price?.live?.tgi_sale_price &&
                (
                  (dataInfo?.around?.price?.live?.tgi_sale_price > 9.99
                    ? 9.99
                    : dataInfo?.around?.price?.live?.tgi_sale_price || 0) * 100
                ).toFixed(2),
              d:
                dataInfo?.around?.price?.live?.tgi_rent_price &&
                (
                  (dataInfo?.around?.price?.live?.tgi_rent_price > 9.99
                    ? 9.99
                    : dataInfo?.around?.price?.live?.tgi_rent_price || 0) * 100
                ).toFixed(2),
            }"
          >
          </calss-tag>
          <calss-tag
            :style="{ paddingBottom: '0.16rem' }"
            title="办公情况"
            :config="[{ name: '平均租金：', value: 'a', right: 'c' }]"
            :tglWidth="$ComJs.bili * 1.78"
            :info="{
              a:
                (dataInfo?.around?.price?.work?.avg_rent_price &&
                  `${dataInfo?.around?.price?.work?.avg_rent_price}元/㎡/月`) ||
                '-',
              c:
                dataInfo?.around?.price?.work?.tgi_rent_price &&
                (
                  (dataInfo?.around?.price?.work?.tgi_rent_price > 9.99
                    ? 9.99
                    : dataInfo?.around?.price?.work?.tgi_rent_price || 0) * 100
                ).toFixed(2),
            }"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.around?.price?.work?.avg_rent_price)
            "
            right="TGL"
          >
          </calss-tag>
          <calss-tag
            :style="{ paddingBottom: '0.16rem' }"
            title="街铺情况"
            :config="[{ name: '平均租金：', value: 'a', right: 'c' }]"
            :tglWidth="$ComJs.bili * 1.78"
            :info="{
              a:
                (dataInfo?.around?.price?.street_shop?.avg_rent_price &&
                  `${dataInfo?.around?.price?.street_shop?.avg_rent_price}元/㎡/月`) ||
                '-',
              c:
                dataInfo?.around?.price?.street_shop?.tgi_rent_price &&
                (
                  (dataInfo?.around?.price?.street_shop?.tgi_rent_price > 9.99
                    ? 9.99
                    : dataInfo?.around?.price?.street_shop?.tgi_rent_price ||
                      0) * 100
                ).toFixed(2),
            }"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.around?.price?.street_shop?.avg_rent_price)
            "
            right="TGL"
          >
          </calss-tag>
        </div>

        <picture-frame
          title="街铺业态占比"
          :prop-stype="{
            height: '5rem',
            margin: '0.24rem 0',
          }"
          v-if="pieList.length"
        >
          <Pie :data="pieList" :percent="true"></Pie>
        </picture-frame>
        <picture-frame
          title="知名品牌"
          :prop-stype="{
            height: '4.2rem',
            margin: '0.24rem 0 0.4 0',
            color: '#000',
          }"
          childClass="all"
        >
          <TestingCharts :data="dataList"></TestingCharts>
        </picture-frame>
        <div
          class="item-title"
          :style="{ marginTop: '0.4rem', marginBottom: '0.24rem' }"
          v-if="
            dataInfo?.traffic?.metro?.num ||
            dataInfo?.traffic?.bus?.num ||
            dataInfo?.traffic?.car?.num
          "
        >
          <div class="left">交通配置</div>
          <div class="right">范围：{{ +stopInfo.type === 2 ? 3 : 1 }}km</div>
        </div>
        <div class="bg-fff">
          <calss-tag
            :style="{ marginTop: '0.16rem', paddingBottom: 0 }"
            title="地铁站"
            titleValue="title"
            details="详情"
            :config="[
              {
                name: '',
                value: 'a',
                icon: require('@/assets/datamap/metro.png'),
              },
            ]"
            @cb="() => dataInfo?.traffic?.metro?.num && goAround(4)"
            :info="{
              title: dataInfo?.traffic?.metro?.num
                ? `${dataInfo?.traffic?.metro?.num}个`
                : '-',
              a: dataInfo?.traffic?.metro?.distance
                ? `地铁站·最近直线距离${dataInfo?.traffic?.metro?.distance}米`
                : '-',
            }"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.traffic?.metro?.num)
            "
          >
          </calss-tag>
          <calss-tag
            :style="{ marginTop: '0.16rem', paddingBottom: 0 }"
            title="公交站"
            titleValue="title"
            details="详情"
            :config="[
              {
                name: '',
                value: 'a',
                icon: require('@/assets/datamap/bus.png'),
              },
            ]"
            @cb="() => dataInfo?.traffic?.bus?.num && goAround(5)"
            :info="{
              title: dataInfo?.traffic?.bus?.num
                ? `${dataInfo?.traffic?.bus?.num}个`
                : '-',
              a: dataInfo?.traffic?.bus?.distance
                ? `公交站·最近直线距离${dataInfo?.traffic?.bus?.distance}米`
                : '-',
            }"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.traffic?.bus?.num)
            "
          >
          </calss-tag>
          <calss-tag
            :style="{
              marginTop: '0.16rem',
            }"
            title="停车场"
            details="详情"
            titleValue="title"
            @cb="() => dataInfo?.traffic?.car?.num && goAround(6)"
            :config="[
              {
                name: '',
                value: 'a',
                icon: require('@/assets/datamap/depot.png'),
              },
            ]"
            :info="{
              title: dataInfo?.traffic?.car?.num
                ? `${dataInfo?.traffic?.car?.num}个`
                : '-',
              a: dataInfo?.traffic?.car?.distance
                ? `停车场·最近直线距离${dataInfo?.traffic?.car?.distance}米`
                : '-',
            }"
            v-if="
              !Object.keys(dataInfo || {}).length ||
              Number(dataInfo?.traffic?.car?.num)
            "
          >
          </calss-tag>
        </div>

        <div class="date-info">以上数据由梦享网络提供</div>
      </div>
    </div>
    <div
      class="attestation-shop"
      v-if="dataInfo && Object.keys(dataInfo).length"
    >
      <van-button type="primary" color="#0057FF" block @click="goStoreClaim"
        >认领店铺</van-button
      >
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineEmits,
  defineProps,
  watch,
  nextTick,
  getCurrentInstance,
} from "vue";
import Row from "./component/row";
import PictureFrame from "./component/picture-frame";
import Line from "./component/line";
import Pie from "./component/pie";
import Explain from "./component/explain";
import CalssTag from "./component/calssTag";
import TestingCharts from "./component/testingCharts";
import { getShopInfo, setFavorites, delFavorites } from "@/axios/poiSearch";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { getTextWidth } from "@/util";
const { appContext } = getCurrentInstance();
const { $ComJs } = appContext?.config?.globalProperties || {}; //全局属性解构

const router = useRouter();

const emit = defineEmits(["back", "refresh"]);
const props = defineProps(["info", "disable"]);
const stopInfo = ref({}); // 基础信息
const collect = ref(false); // 是否收藏
const collectDisable = ref(false); // 收藏加载

let dataInfo = ref(props.info);
let pieList = ref([]); //街铺业态占比数据
let dataList = ref([]); // 知名品牌数据

const getSection = (num, i) => {
  let arr = [];
  for (let index = i; index < num; index++) {
    arr.push(index);
  }
  return arr;
};

// 去店铺领取
const goStoreClaim = () => {
  router.push({
    path: "/my/store-claim",
    query: {
      type: "claim",
      data: JSON.stringify({
        name: dataInfo.value.poi_name, // 店铺名称
        coord: dataInfo.value.coord, // 地图地址
        address: dataInfo.value.address, // 店铺地址
        poiInfo: { id: dataInfo.value.poi_id }, // 环境信息
      }),
    },
  });
};

// 去查看周边数据
const goAround = (type) => {
  if (props.disable) return;
  router.push({
    path: "/home/around",
    query: {
      coord: dataInfo.value.coord,
      list: JSON.stringify({
        type,
        id: dataInfo.value.poi_id,
        poiType: dataInfo.value?.type,
      }),
    },
  });
};

// 收藏
const setCollect = async () => {
  if (!stopInfo.value?.poi_id) return;
  collectDisable.value = true;
  try {
    let res = null;
    if (!collect.value) {
      res = await setFavorites({
        p_id: stopInfo.value.poi_id,
      });
    } else {
      res = await delFavorites(collect.value);
    }
    collectDisable.value = false;
    if (res.code === 0) {
      Toast({
        position: "bottom",
        message: !collect.value ? "收藏成功" : "取消收藏成功",
      });
      collect.value = res?.data?.favorite_id || null;
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    collectDisable.value = false;
  }
};

// 获取商店数据
const xShopInfo = async (info) => {
  console.log("dataInfo-1", info);

  try {
    let res = await getShopInfo({
      p_id: info.poi_id,
    });

    if (res.code === 0) {
      dataInfo.value = {
        basicInfo: info,
        ...res.data,
      };

      pieList.value = res.data.around.street_shop_format.map((el) => ({
        name: el.name,
        value: el.num,
      }));
      dataList.value = (res.data?.around.known_brand || [])
        .map((el) => ({
          name: el.name,
          image: el.logo,
          value: el.num,
        }))
        .sort((a, b) => b.value - a.value);
      stopInfo.value = {
        name: res.data.poi_name,
        arr: info.address || res.data.address,
        logo: res.data.logo,
        category_list: res.data.category_list.filter((el) => el),
        poi_id: info.poi_id,
        type: res.data.type,
      };
      collect.value = res.data?.self?.favorite_id;
      let tgl = [
        res.data?.around?.price?.live?.tgi_sale_price,
        res.data?.around?.price?.live?.tgi_rent_price,
        res.data?.around?.price?.work?.tgi_rent_price,
        res.data?.around?.price?.street_shop?.tgi_rent_price,
      ].sort((a, b) => b - a);

      emit("refresh", {
        collect: res.data?.self?.favorite_id,
        logo: res.data.logo,
        address: res.data.address,
        title: res.data.poi_name,
        poi_id: info.poi_id,
      });
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    emit("refresh", {
      collect: false,
      logo: info.logo,
      address: info.address,
      title: info.poi_name,
      poi_id: info.poi_id,
    });
  }
};

watch(
  () => props.info,
  (end, start) => {
    if (!end) return;
    dataInfo.value = {};
    pieList.value = [];
    dataList.value = [];
    collect.value = false;

    stopInfo.value = {
      name: end.poi_name,
      arr: end.address,
      logo: end.logo,
      category_list: end.category_list,
      poi_id: end.poi_id,
      type: end.type,
    };

    if (!end?.poi_id) {
      Toast({
        position: "bottom",
        message: "暂无数据",
      });
      emit("refresh", {
        collect: false,
        logo: end?.logo,
        address: end?.address,
        title: end?.poi_name,
        poi_id: end?.poi_id,
      });
      return;
    }
    xShopInfo(end);
  }
);

defineExpose({
  goStoreClaim,
});
</script>

<style lang="scss">
// 因为真机没显示css样式所以放到全局
</style>
