<template>
  <div class="do-row-tab" :style="rowStyle" @click="submt">
    <div class="img">
      <van-skeleton :loading="!!props.skeleton" avatar>
        <img
          :src="
            props?.info[props.img]
              ? props?.info[props.img]
              : require('@/assets/datamap/replace-logo.png')
          "
          alt=""
          v-if="props.img"
        />
      </van-skeleton>
    </div>
    <div class="content">
      <div class="title" v-if="props.title">
        <van-skeleton :loading="!!props.skeleton" :row="1">
          {{ props?.info[props.title] || "-" }}
        </van-skeleton>
      </div>
      <div class="address" v-if="props.content">
        <van-skeleton :loading="!!props.skeleton" :row="1">
          {{ props?.info[props.content] || "-" }}
        </van-skeleton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps([
  "info",
  "title",
  "content",
  "img",
  "rowStyle",
  "skeleton",
]);
const emit = defineEmits("submt");
const submt = () => {
  emit("submt", props.info);
};
</script>

<style lang="scss">
// 因为真机没显示css样式所以放到全局
</style>
