import { debounce } from "@/util";
import { getPoiList } from "@/axios/poiSearch";
import $Hbuild from "@/util/hbuild";
import $ComJs from "@/util";

class Cluster {
  constructor(map, drawer) {
    this.drawer = drawer; // 抽屉实例
    this.map = map || null; // 渲染的地图实例
    // 自有POI聚合点图层
    this.baseMarkerClusterLayer = null;
    // 自有POI marker点图层
    this.baseMarkerLayer = null;

    // POI聚合点图层
    this.poiMarkerClusterLayer = null;
    /** 展示搜素结果POI */
    this.poiLayer = null;

    // POI点id集合
    this.poiIdArr = [];

    // 查看单个详情POI
    this.showDetailPoiId = null;

    /** 高亮的POI信息列表 */
    this.poiList = [];

    /** 监听事件集合 */
    this.event = {};

    // --- 这个标识  代码我改过的代码
    // --- 保存当前高亮点数据
    this.geome = null;
  }

  onInit(map, drawer) {
    this.drawer = drawer; // 抽屉实例
    this.map = map || null; // 渲染的地图实例
    this.map.on("bounds_changed", this.boundsChangedFn);
    this.map.emit("bounds_changed");
    this.drawer.on("scroll", () => {
      this.updateBounds();
    });

    // ---  清除上次地图实例用到的参数 并且处理返回当前页面逻辑
    if (this.baseMarkerLayer) {
      this.poiMarkerClusterLayer?.emit("cluster_changed");
      this.poiMarkerClusterLayer = null;
      this.baseMarkerClusterLayer?.emit("cluster_changed");
      this.baseMarkerClusterLayer = null;
      this.baseMarkerLayer = null;
      this.poiLayer = null;
      if (this.geome) {
        this.setShowDetailPoi(this.geome);
      }
    }
  }

  /** 更新视口 */
  updateBounds(coord) {
    // fix: 初始化地图偏移
    this.map.setOffset({
      x: 0,
      y: 0,
    });
    if (coord) {
      this.setPanTo(new window.TMap.LatLng(...coord.split(",")));
      return false;
    }

    if (this.showDetailPoiId) {
      if (this.poiLayer) {
        const geometries = this.poiLayer.getGeometries();
        if (geometries.length > 1) {
          //初始化
          const bounds = new window.TMap.LatLngBounds();
          geometries.forEach((geo) => {
            //若坐标点不在范围内，扩大bounds范围
            if (bounds.isEmpty() || !bounds.contains(geo.position)) {
              bounds.extend(geo.position);
            }
          });

          //设置地图可视范围
          this.map.fitBounds(bounds, {
            padding: {
              top: $Hbuild.getStatusBarHeight() + $ComJs.bili,
              bottom: this.drawer.offset + $ComJs.bili,
              left: $ComJs.bili,
              right: $ComJs.bili,
            }, // 自适应边距
          });
        } else if (geometries.length === 1) {
          this.setPanTo(geometries[0].position);
        }
      }
    } else {
      if (this.poiMarkerClusterLayer) {
        const geometries = this.poiMarkerClusterLayer.getGeometries();

        if (geometries.length > 1) {
          //初始化
          const bounds = new window.TMap.LatLngBounds();
          geometries.forEach((geo) => {
            //若坐标点不在范围内，扩大bounds范围
            if (bounds.isEmpty() || !bounds.contains(geo.position)) {
              bounds.extend(geo.position);
            }
          });
          //设置地图可视范围
          this.map.fitBounds(bounds, {
            padding: {
              top: $Hbuild.getStatusBarHeight() + $ComJs.bili,
              bottom: this.drawer.offset + $ComJs.bili,
              left: $ComJs.bili,
              right: $ComJs.bili,
            }, // 自适应边距
            minZoom: 10,
            maxZoom: 16,
          });
        } else if (geometries.length === 1) {
          this.setPanTo(geometries[0].position);
        }
      }
    }
  }

  /** 将地图中心平滑移动到指定的经纬度坐标。 */
  setPanTo(position) {
    this.map.setOffset({
      x: 0,
      y:
        (-this.drawer.offset + $Hbuild.getStatusBarHeight() + $ComJs.bili * 1) /
        2,
    });
    this.map.panTo(position);
  }

  /** 请求poi点 */
  boundsChangedFn = debounce(() => {
    const zoom = this.map.getZoom();
    const bounds = this.map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const neLat = ne.getLat();
    const neLng = ne.getLng();
    const swLat = sw.getLat();
    const swLng = sw.getLng();
    const nw = [neLat, swLng]; // 西北
    const se = [swLat, neLng]; // 东南
    getPoiList({
      top_left: nw.join(","),
      bottom_right: se.join(","),
      level: Math.floor(zoom * 100) / 100,
    }).then((res) => {
      const pointGeoList = this.poiConvertToGeometries(
        res.data,
        "default",
        true
      );
      const curZoom = this.map.getZoom();
      if (curZoom > 12) {
        if (this.baseMarkerClusterLayer) {
          this.baseMarkerClusterLayer.setGeometries(pointGeoList);
        } else {
          this.baseMarkerClusterLayer = this.createClusterLayer(
            "baseMarkerCluster",
            pointGeoList
          );

          /** marker点集合 */
          let makerGeometries = [];
          // 监听聚合簇变化
          this.baseMarkerClusterLayer.on("cluster_changed", () => {
            makerGeometries = [];
            // 根据新的聚合簇数组生成新的覆盖物和点标记图层
            const clusters = this.baseMarkerClusterLayer.getClusters();
            clusters.forEach((item) => {
              // 过滤掉自有POI数据里面的高亮POI点
              const firstGeo = item.geometries[0];
              if (this.showDetailPoiId) {
                if (this.showDetailPoiId !== firstGeo.id) {
                  makerGeometries.push(firstGeo);
                }
              } else {
                if (!this.poiIdArr.includes(firstGeo.id)) {
                  makerGeometries.push(firstGeo);
                }
              }
            });

            this.updateMarker(makerGeometries);
          });
        }
      } else {
        this.baseMarkerClusterLayer?.setGeometries([]);
        this.baseMarkerClusterLayer?.emit("cluster_changed");
      }
    });
  }, 300);

  /** 创建marker聚合图层 */
  createClusterLayer(id, geometries) {
    const clusterLayer = new window.TMap.MarkerCluster({
      id,
      map: this.map,
      enableDefaultStyle: false, // 关闭默认样式
      minimumClusterSize: 3,
      zoomOnClick: true,
      gridSize: 60,
      averageCenter: false,
      geometries,
    });
    return clusterLayer;
  }

  /**
   * 添加监听事件
   * @param {string} eventName - 事件名称
   * @param {()=>void} func - 事件回调
   */
  on(eventName, func) {
    if (this.event[eventName]) {
      this.event[eventName].push(func);
    } else {
      this.event[eventName] = [func];
    }
  }

  /** 清除marker点 */
  clear() {
    this.showDetailPoiId = null;
    this.poiList = [];
    this.poiMarkerClusterLayer?.emit("cluster_changed");

    // ---  清除上次地图实例用到的参数
    this.poiMarkerClusterLayer = null;
    this.baseMarkerClusterLayer?.emit("cluster_changed");
    this.baseMarkerClusterLayer = null;
    this.baseMarkerLayer = null;
    this.poiLayer = null;
    this.poiList = [];
    this.poiIdArr = [];
  }

  /**
   * 自有POI渲染
   * 没有marker图层就创建，已创建图层就更新marker点位
   */
  updateMarker(geometries) {
    if (this.baseMarkerLayer) {
      this.baseMarkerLayer.setGeometries(geometries);
    } else {
      // 高亮点图层
      this.baseMarkerLayer = this.createMarkerLayer(
        "baseMarkerLayer",
        geometries,
        99
      );
    }
  }

  /**
   * 渲染 Poi marker点
   * 没有marker图层就创建，已创建图层就更新marker点位
   */
  updatePoiMarker(geometries, move) {
    if (this.poiLayer) {
      this.poiLayer.setGeometries(geometries);
    } else {
      this.poiLayer = this.createMarkerLayer("poiLayer", geometries, 100);
      if (!move) {
        this.updateBounds();
      }
    }
  }

  /**
   * 创建marker点图层
   * @param {number} id - 图层id
   * @param {PointGeometry} geometries - 点图形数据
   * @param {number} zIndex - 图层绘制顺序
   * @returns {TMap.MultiMarker}
   */
  createMarkerLayer(id, geometries, zIndex) {
    const layer = new window.TMap.MultiMarker({
      id,
      map: this.map,
      zIndex,
      styles: {
        default: new window.TMap.MarkerStyle({
          width: 6,
          height: 6,
          size: 12,
          color: "#616b81", //颜色属性
          strokeColor: "#fff",
          direction: "bottom",
          offset: { x: 0, y: 4 },
          src: require("@/assets/map/label.png"),
        }),
        poiMarker: new window.TMap.MarkerStyle({
          width: 20,
          height: 27,
          size: 12,
          color: "#4294ff",
          strokeColor: "#fff",
          direction: "bottom",
          src: require("@/assets/map/poi.png"),
        }),
        activePoiMarker: new window.TMap.MarkerStyle({
          width: 32,
          height: 40,
          size: 12,
          color: "rgba(2,4,15,0.85)",
          direction: "bottom",
          src: require("@/assets/map/poi-big.png"),
        }),
      },
      geometries,
    });
    /** 监听高亮点点击事件 */
    layer.on("click", (e) => {
      if (e.geometry.styleId !== "activePoiMarker") {
        this.onClick(e);
      }
    });
    return layer;
  }

  /**
   * 监听地图poi点击事件
   * @param {event} e - 事件回调
   */
  onClick(e) {
    const geo = e.geometry;
    const detailGeo = {
      ...geo,
      content: geo.properties.content,
    };
    /** 触发cluster click事件 */
    this.event?.click.forEach((fn) => {
      fn(detailGeo);
    });
    this.setShowDetailPoi(detailGeo);
  }

  /** 设置高亮点ID */
  setPoiId(poiIdArr) {
    this.poiIdArr = poiIdArr;
  }

  /**
   * 高亮的POI
   * @param {poi[]} poiList - poi列表
   */
  // TODO:修改方法名称
  setActivePoi(poiList, move) {
    this.poiList = poiList;
    const poiIdArr = poiList.map((item) => item.poi_id);
    this.setPoiId(poiIdArr);
    const poiGeo = this.poiConvertToGeometries(poiList, "poiMarker");
    if (this.poiMarkerClusterLayer) {
      this.poiMarkerClusterLayer.setGeometries(poiGeo);
      // 异步获取不可靠
      setTimeout(() => {
        // fix: 修复抽屉切换过快导致POI详情点不显示
        if (!this.showDetailPoiId) {
          const clusters = this.poiMarkerClusterLayer.getClusters();
          const makerGeometries = clusters.map((item) => {
            return {
              ...item.geometries[0],
              styleId: "poiMarker",
            };
          });
          this.updatePoiMarker(makerGeometries);
        }
      }, 1000);
    } else {
      this.poiMarkerClusterLayer = this.createClusterLayer(
        "poiMarkerCluster",
        poiGeo
      );
      // 更新视口
      if (!move) {
        this.updateBounds();
      }
      /** marker点集合 */
      let makerGeometries = [];
      // 监听聚合簇变化
      this.poiMarkerClusterLayer.on("cluster_changed", () => {
        if (!this.showDetailPoiId) {
          makerGeometries = [];
          const clusters = this.poiMarkerClusterLayer.getClusters();

          makerGeometries = clusters.map((item) => {
            return {
              ...item.geometries[0],
              styleId: "poiMarker",
            };
          });
          this.updatePoiMarker(makerGeometries, move);
        }
      });
    }
  }

  /** 设置查看详情的POI */
  setShowDetailPoi(geometrie) {
    // ---
    this.geome = geometrie;

    const { id, position } = geometrie;
    this.showDetailPoiId = id;
    const geometries = [
      {
        ...geometrie,
        styleId: "activePoiMarker",
      },
    ];
    if (this.poiLayer) {
      this.poiLayer.setGeometries(geometries);
    } else {
      // 高亮点图层
      this.poiLayer = this.createMarkerLayer("poiLayer", geometries, 100);
    }
    this.map.setZoom(16);
    this.map.setOffset({
      x: 0,
      y:
        (-this.drawer.offset + $Hbuild.getStatusBarHeight() + $ComJs.bili * 1) /
        2,
    });
    this.map.panTo(position);
  }

  /** 恢复暂存的poi点 */
  recover() {
    this.showDetailPoiId = null;
    this.setActivePoi(this.poiList);
  }

  /** 高亮单个POI */
  //TODO:修改参数为对象
  focus(poiList) {
    const geos = this.poiConvertToGeometries(poiList, "activePoiMarker", true);
    this.setShowDetailPoi(geos[0]);
  }

  /**
   * 转换poi为geometries
   * @param {poi} poiList - poi列表数据
   * @param {string} styleId - 点数据样式id
   * @param {boolean} showName - 是否展示marker点名称
   * @returns {Geometries}
   */
  poiConvertToGeometries(poiList, styleId, showName = false) {
    const geo = poiList.map((poi) => {
      const { poi_id, poi_name, coord } = poi;
      return {
        properties: {
          content: poi_name,
        },
        content: showName ? poi_name : undefined,
        id: poi_id,
        position: new window.TMap.LatLng(...coord.split(",")),
        styleId,
      };
    });
    return geo;
  }
}

export default Cluster;
