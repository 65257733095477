<template>
  <div
    class="do-fn-line"
    :id="containerID"
    :style="{ width: '100%', height: '100%' }"
  ></div>
</template>

<script setup>
import { onMounted, defineEmits, defineProps, watch } from "vue";
import Utils from "@/util";
import * as echarts from "echarts";
import { ref } from "vue";
const containerID = Utils.guid();
const emit = defineEmits(["cb"]);
const props = defineProps(["x", "data", "percent", "dataZoom", "legend"]);
let charts = ref(null);

onMounted(() => {
  var chartDom = document.getElementById(containerID);
  console.log("onMounted----", chartDom);
  if (!chartDom) return;
  var myChart = echarts.init(chartDom, null, { renderer: "svg" });
  charts = myChart;
  var option = getConfig(props.data, props.x);
  option && myChart.setOption(option);
  emit("cb", myChart);
});

watch(
  () => props.data,
  (end, start) => {
    charts.setOption(getConfig(end, props.x), { notMerge: true });
  }
);

const getConfig = (data, x) => {
  let list = [];
  if (Object.prototype.toString.call(data && data[0]) === "[object Object]") {
    list = [
      {
        type: "bar",
        name: "节假日",
        stack: "one",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#e3c4a9" },
            { offset: 1, color: "#e38c41" },
          ]),
        },
        data: [],
      },
      {
        type: "bar",
        name: "工作日",
        stack: "one",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#788DFF" },
            { offset: 1, color: "#0057FF" },
          ]),
        },
        data: [],
      },
    ];
    data.forEach((el) => {
      if (!el.is_workday) {
        list[0].data.push({
          value: props.percent ? el.value * 100 : el.value,
          date: el.date,
        });
        list[1].data.push("-");
      } else {
        list[1].data.push({
          value: props.percent ? el.value * 100 : el.value,
          date: el.date,
        });
        list[0].data.push("-");
      }
    });
  } else {
    list = [
      {
        type: "bar",
        stack: "one",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#788DFF" },
            { offset: 1, color: "#0057FF" },
          ]),
        },
        data: data,
      },
    ];
  }

  let config = {
    legend: {
      data: props.legend,
      top: 0,
      left: 0,
      itemWidth: 10,
      itemHeight: 5,
      padding: 0,
      textStyle: {
        fontSize: 10,
      },
    },
    tooltip: {
      valueFormatter: (value) => {
        return props.percent ? `${value.toFixed(2)}%` : value;
      }, //y轴数值，带百分号
      formatter: (el) => {
        return `${el.marker}${
          data[el.dataIndex]?.date
            ? data[el.dataIndex].date.toString().slice(5)
            : el.name
        }：${
          data[el.dataIndex]?.value
            ? data[el.dataIndex]?.value
            : props.percent
            ? `${data[el.dataIndex].toFixed(2)}%`
            : data[el.dataIndex].toFixed(2)
        }`;
      },
    },
    xAxis: {
      type: "category",
      data: (data && x) || [],
      axisLine: {
        lineStyle: {
          color: "rgba(2,4,13,0.12)",
        },
      },
      axisLabel: {
        color: "rgba(2,4,13,0.45)",
      },
      axisTick: {
        alignWithLabel: true,
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          color: "rgba(2,4,13,0.25 )",
        },
      },
      axisLabel: {
        color: "rgba(2,4,13,0.25)",
        formatter: props.percent ? "{value}%" : "{value}", //y轴数值，带百分号
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    grid: {
      left: "auto",
      right: 20,
      bottom: "auto",
      top: "24",
      containLabel: true,
    },
    series: list,
  };
  // if (props.dataZoom) {
  //   config.dataZoom = [
  //     {
  //       type: "inside", // 内嵌入图标中
  //       startValue: data?.length ? data?.length - 7 : 0, // 用绝对值的方式定义了窗口数据默认展示的数据开始范围
  //       endValue: data?.length ? data?.length - 1 : 0, // 用绝对值的方式定义了窗口数据默认展示的数据终止范围
  //       filterMode: "filter",
  //       /**
  //           不指定时，当 dataZoom-slider.orient 为 'horizontal'时，
  //           默认控制和 dataZoom 平行的第一个 xAxis。但是不建议使用默认值，建议显式指定。
  //           如果是 number 表示控制一个轴，如果是 Array 表示控制多个轴。
  //           */
  //       xAxisIndex: [0],
  //     },
  //   ];
  // }
  return config;
};
</script>

<style lang="scss">
// 因为真机没显示css样式所以放到全局
</style>
