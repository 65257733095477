import { nextTick } from "vue";
import $Hbuild from "@/util/hbuild";
import $ComJs from "@/util";

/**
 * 抽屉状态管理
 */
class Drawer {
  constructor(positionOption) {
    this.positionOption = positionOption;
    /** 抽屉实例集合 */
    this.drawerRefMap = {};
    this.isOpenId = null;
    this.offset = 0;
    /** 监听事件集合 */
    this.event = {};

    /** 第一个抽屉id */
    this.firstAddId = undefined;
  }
  /**
   * 配置抽屉上中下对应的位置信息
   * @param {*} positionOption
   * @example
   *  ```javascript
   *  {
   *     top: 300,
   *     middle: 200,
   *     bottom: 100,
   *     hidden: 0
   *  }
   * ```
   */
  setPositionOption(positionOption) {
    this.positionOption = positionOption;
  }
  /**
   * 添加抽屉
   * @param {number|string} id 当前抽屉的id
   * @param {drawerRef} drawerRef 抽屉组件实例
   */
  add(id, drawerRef) {
    if (this.firstAddId === undefined) {
      this.firstAddId = id;
      nextTick(() => {
        drawerRef.value.changeScroll(this.positionOption.hidden);
      });
    }
    this.drawerRefMap[id] = drawerRef;
  }

  /**
   * 查询抽屉在屏幕中的位置
   * @param {number|string} id - 查询抽屉的id，不传默认查询当前打开的抽屉的位置
   * @returns {'top'|'middle'|'bottom'|'hidden'}
   */
  getPosition(id = this.isOpenId) {
    const transitionY = this.drawerRefMap[id]?.value?.transitionY;
    let curPosition = "bottom";
    Object.keys(this.positionOption).forEach((position) => {
      if (this.positionOption[position] === transitionY) {
        curPosition = position;
      }
    });
    return curPosition;
  }

  /**
   * 更新当前抽屉位置，使用之前应先使用 `open()` 打开一个抽屉，不然不生效
   * @param {'top'|'middle'|'bottom'|'hidden'} position - 更新后的位置
   */
  update(position) {
    nextTick(() => {
      if (this.isOpenId !== null && this.isOpenId !== undefined) {
        const y = this.positionOption[position];
        const curDrawerRef = this.drawerRefMap[this.isOpenId];
        curDrawerRef?.value?.toggleEnableScroll(true);
        curDrawerRef?.value?.changeScroll(y);
        this.offset =
          this.positionOption.bottom - this.positionOption[position];
      }
    });
  }

  /** 关闭所有抽屉 */
  close() {
    nextTick(() => {
      this.isOpenId = null;
      Object.keys(this.drawerRefMap).forEach((keyId) => {
        const curDrawerRef = this.drawerRefMap[keyId];
        curDrawerRef?.value?.toggleEnableScroll(false);
        if (keyId == this.firstAddId) {
          curDrawerRef?.value?.changeScroll(this.positionOption.hidden);
        } else {
          curDrawerRef?.value?.changeScroll(this.positionOption.bottom);
        }
      });
      this.offset = 0;
      this.emit();
    });
  }

  /** 重新计算 BetterScroll */
  scrollRefresh() {
    nextTick(() => {
      const curDrawerRef = this.drawerRefMap[this.isOpenId];
      if (curDrawerRef) {
        curDrawerRef.value?.scrollRefresh();
      }
    });
  }

  // 停止上拉加载
  finishPullUp() {
    nextTick(() => {
      const curDrawerRef = this.drawerRefMap[this.isOpenId];
      if (curDrawerRef) {
        curDrawerRef.value?.finishPullUp();
      }
    });
  }

  // 抽屉滚动到指定位置
  scrollTo(x, y) {
    nextTick(() => {
      const curDrawerRef = this.drawerRefMap[this.isOpenId];
      if (curDrawerRef) {
        curDrawerRef.value?.scrollTo(x, y);
      }
    });
  }

  /**
   * 打开对应ID的抽屉
   * @param {number|string} id
   * @param {'top'|'middle'|'bottom'|'hidden'} position - 要打开抽屉的位置
   */
  open(id, position) {
    nextTick(() => {
      if (this.drawerRefMap[id]) {
        this.isOpenId = id;
        Object.keys(this.drawerRefMap).forEach((keyId) => {
          const curDrawerRef = this.drawerRefMap[keyId];
          if (keyId == id) {
            curDrawerRef?.value?.scrollRefresh();
            curDrawerRef?.value?.toggleEnableScroll(true);
            curDrawerRef?.value?.changeScroll(this.positionOption[position]);
          } else {
            curDrawerRef?.value?.toggleEnableScroll(false);
            curDrawerRef?.value?.changeScroll(this.positionOption.hidden);
          }
          console.log("toggleChildrenScroll");
          // 关闭子级滚动
          curDrawerRef?.value?.toggleChildrenScroll(false);
        });
        // 全屏高度 - 头部 - 抽屉高度
        this.offset =
          this.positionOption.hidden -
          $ComJs.bili * 0.88 -
          this.positionOption[position];

        this.emit();
      }
    });
  }

  /** 初始监听事件 */
  emit() {
    Object.keys(this.event).forEach((keyEvent) => {
      this.event[keyEvent].forEach((fn) => {
        fn();
      });
    });
  }

  /** 监听事件 */
  on(eventName, fun) {
    if (this.event[eventName]) {
      this.event[eventName].push(fun);
    } else {
      this.event[eventName] = [fun];
    }
  }
}

export default Drawer;
