import axios from "./http";
import PoiList from "./poilist";

//搜索poi
export const getShopInfo = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi",
    params,
  });
};

//poi 详情
export const search = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi/search",
    params,
  });
};

// 获取推荐业态
export const getSuggestionCate = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/suggestion_cate",
  });
};

// 获取poi
export const getPoiList = (params) => {
  return PoiList.getData(params);
};

// 搜索poi_map
export const getSearchMap = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi/search/map",
    params,
  });
};

// 收藏 POI
export const setFavorites = (params) => {
  return axios({
    method: "post",
    url: "/api/v1/account/favorites",
    data: params,
  });
};

// 取消收藏 POI
export const delFavorites = (id) => {
  return axios({
    method: "delete",
    url: `/api/v1/account/favorites/${id}`,
  });
};
