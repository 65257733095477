<template>
  <div class="do-com-poi-info-header">
    <div class="phone-status">
      <div></div>
      <img
        :src="require('@/assets/datamap/del.png')"
        alt=""
        @click="back"
        class="close"
        v-if="props.close"
      />
    </div>
    <div :class="`title ${imgRadius ? 'imgRadius' : ''}`">
      <Row
        :info="props.info || {}"
        img="logo"
        title="title"
        :content="props.collect ? 'address' : false"
        :row-style="{ height: '0.72rem' }"
        :skeleton="!Object.keys(props.info || {}).length"
      ></Row>
      <div>
        <van-skeleton
          :loading="!Object.keys(props.info || {}).length"
          :row="1"
          :style="{
            width: '1.28rem',
            height: '0.56rem',
            background: '#f2f3f5',
            padding: 0,
          }"
        >
          <div
            class="title-rigth"
            @click="() => !collectDisable && setCollect()"
            v-if="props.collect"
          >
            <van-loading size="0.28rem" v-if="collectDisable" color="#0057ff" />
            <img
              :src="require('@/assets/datamap/not-collect.png')"
              alt=""
              v-else-if="!collect"
            />
            <img :src="require('@/assets/datamap/collect.png')" alt="" v-else />
            收藏
          </div>
        </van-skeleton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import Row from "@/views/datamap/poi-info/component/row";
import { Toast } from "vant";
import { setFavorites, delFavorites } from "@/axios/poiSearch";

const collectDisable = ref(false); // 收藏加载
const props = defineProps(["info", "close", "collect", "imgRadius"]);
const emit = defineEmits(["back"]);
const collect = ref(props?.info?.collect);

watch(
  () => props.info,
  (end, sta) => {
    collect.value = end.collect;
  }
);

const back = () => {
  emit("back");
};

// 收藏
const setCollect = async () => {
  if (!props?.info?.poi_id) return;
  collectDisable.value = true;
  try {
    let res = null;
    if (!collect.value) {
      res = await setFavorites({
        p_id: props?.info?.poi_id,
      });
    } else {
      res = await delFavorites(collect.value);
    }
    collectDisable.value = false;
    if (res.code === 0) {
      Toast({
        position: "bottom",
        message: !collect.value ? "收藏成功" : "取消收藏成功",
      });
      collect.value = res?.data?.favorite_id || null;
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    collectDisable.value = false;
  }
};
</script>
<style lang="scss"></style>
